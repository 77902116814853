import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

import ReactGA from "react-ga4";

import MainRoute from "routes";

import LoadingPage from "components/common/components/loading";
import { linkWithLanguage } from "components/helpers/commonLinks";
import StaffDashboardSidebar from "components/staff/dashboard/staffDashboard/components/StaffDashboardSidebar";
import Cookies from "js-cookie";
import { Suspense, useEffect } from "react";
import Navbar from "./components/navigation/navbar";
import { persistor } from "./redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { logoutButton } from "redux/slices/common-action-utils";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTIC_ID; // OUR_TRACKING_ID

if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

ConfigProvider.config({
  theme: {
    primaryColor: "#00a84f",
  },
});

const App = () => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      // Check for the logout event
      if (event.key === "logout") {
        persistor.purge();
        window.location.href = linkWithLanguage("/");
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    /**
     * This app version is used to mantain sync for the login sessions.
     * Primarily this is used when we have some changes in the login flow api.
     */
    const appVersion = "1.0.1";
    const storedVersion = Cookies.get("app_version");
    if (storedVersion && storedVersion !== appVersion) {
      logoutButton("/accounts/?logout=true");
    } else {
      // Set the current version in cookies
      Cookies.set("app_version", appVersion, { expires: 365 });
    }
  }, []);

  const navbarHiddenUrls = ["staff/dashboard"];
  const isNavbarHidden = navbarHiddenUrls.some((hiddenUrl) =>
    window.location.pathname.includes(hiddenUrl)
  );
  return (
    <div className="app">
      <StaffDashboardSidebar>
        <div className="main-content">
          {!isNavbarHidden && <Navbar />}
          <Suspense fallback={<LoadingPage />}>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GG_APP_ID || ""}
            >
              <MainRoute />
            </GoogleOAuthProvider>
          </Suspense>
        </div>
      </StaffDashboardSidebar>
    </div>
  );
};

export default App;
