import {
  Avatar,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { memo, useState } from "react";

import {
  Apartment,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  ExpandLess,
  ExpandMore,
  LockReset,
  ManageAccounts,
  Person,
  Settings,
} from "@mui/icons-material";
import { defaultColors } from "components/common/Materialui/themes/main/default";
import UserSettingsModal from "components/common/components/logout/UserSettingsModal";
import Footer from "components/footer/Footer";
import { linkWithLanguage } from "components/helpers/commonLinks";
import useDisclosure from "components/hooks/useDisclosure";
import Logo from "components/navigation/logo";
import useNavigation from "components/navigation/navbar/hooks/useNavigation";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { logoutButton } from "redux/slices/common-action-utils";

function StaffDashboardSidebar({ children }) {
  const [dashboardOpen, { toggle: toggleDashboard }] = useDisclosure();
  const [managementLinksOpen, { toggle: toggleManagement }] = useDisclosure();
  const [importantLinksOpen, { toggle: toggleImportantLinks }] =
    useDisclosure();
  const [sidebarOpened, { toggle: sidebarToggle }] = useDisclosure();

  const [settingsModal, setsettingsModal] = useState(false);
  const { loyacStaff } = useSelector((state) => state.rootAuth);

  const {
    loyacStaff: loyacStaffOptions,
    staffDashboardLinks,
    importantLinks,
  } = useNavigation();

  const history = useHistory();

  const [subscribtionStatus, setsubscribtionStatus] = useState();

  return (
    <>
      <Box display={"flex"} width="100%">
        <CssBaseline />
        {/* TODO: temporary fix */}
        {loyacStaff && window.location.pathname.includes("staff/dashboard") && (
          <MuiDrawer
            variant="permanent"
            anchor="left"
            open={sidebarOpened}
            PaperProps={{
              sx: {
                paddingBottom: 5,
                justifyContent: "space-between",
                backgroundColor: defaultColors.primary,
              },
            }}
          >
            <div>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <IconButton onClick={() => sidebarToggle()}>
                  {sidebarOpened ? (
                    <ChevronLeft fontSize="large" sx={cWhite} />
                  ) : (
                    <ChevronRight fontSize="large" sx={cWhite} />
                  )}
                </IconButton>
              </Box>
              {sidebarOpened && (
                <Box {...flexCenter}>
                  <Logo color="white" width="120" />
                </Box>
              )}
              <List>
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onMouseEnter={() => {
                    if (!sidebarOpened) sidebarToggle();
                  }}
                >
                  <ListItemButton onClick={toggleDashboard}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sidebarOpened ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Dashboard sx={cWhite} />
                    </ListItemIcon>
                    {sidebarOpened && (
                      <ListItemText
                        primary={
                          <Typography
                            color={"white"}
                            fontSize={18}
                            fontWeight={600}
                          >
                            {" "}
                            Dashboard
                          </Typography>
                        }
                      />
                    )}
                    {sidebarOpened && <ToggleIcon bool={dashboardOpen} />}
                  </ListItemButton>
                </ListItem>
                <Divider color="white" />
                <Collapse in={dashboardOpen}>
                  <List>
                    {staffDashboardLinks.map((e, index) => {
                      return (
                        <Box ml={6} key={index}>
                          {generateDashboardLiks(e, history)}
                        </Box>
                      );
                    })}
                  </List>
                </Collapse>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton onClick={toggleManagement}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sidebarOpened ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ManageAccounts sx={cWhite} />
                    </ListItemIcon>
                    {sidebarOpened && (
                      <ListItemText
                        primary={
                          <Typography
                            color={"white"}
                            fontSize={18}
                            fontWeight={600}
                          >
                            {" "}
                            Management
                          </Typography>
                        }
                      />
                    )}
                    {sidebarOpened && <ToggleIcon bool={managementLinksOpen} />}
                  </ListItemButton>
                </ListItem>
                <Divider color="white" />
                <Collapse in={managementLinksOpen}>
                  <List>
                    {loyacStaffOptions.map((e, index) => {
                      if (e.to !== "/staff/dashboard")
                        return (
                          <Box ml={6} key={index}>
                            {generateDashboardLiks(e, history)}
                          </Box>
                        );
                      return null;
                    })}
                  </List>
                </Collapse>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton onClick={toggleImportantLinks}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sidebarOpened ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Apartment sx={cWhite} />
                    </ListItemIcon>
                    {sidebarOpened && (
                      <ListItemText
                        primary={
                          <Typography
                            color={"white"}
                            fontSize={18}
                            fontWeight={600}
                          >
                            {" "}
                            Important Links
                          </Typography>
                        }
                      />
                    )}
                    {sidebarOpened && <ToggleIcon bool={importantLinksOpen} />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={importantLinksOpen}>
                  <List>
                    {importantLinks.map((e, index) => {
                      return (
                        <Box ml={6} key={index}>
                          {generateDashboardLiks(e, history)}
                        </Box>
                      );
                    })}
                  </List>
                </Collapse>
              </List>
              <Divider color="white" />
            </div>
            <List>
              <StaffProfile
                loyacStaff={loyacStaff}
                history={history}
                sidebarOpened={sidebarOpened}
              />
            </List>
          </MuiDrawer>
        )}
        <Box width={"100%"}>
          <Box component={"main"} sx={{ flexGrow: 1 }}>
            {children}
          </Box>
          <Footer />
        </Box>
      </Box>

      {loyacStaff && (
        <UserSettingsModal
          setsubscribtionStatus={setsubscribtionStatus}
          subscribtionStatus={subscribtionStatus}
          open={settingsModal}
          handleClose={() => {
            setsettingsModal(false);
            setsubscribtionStatus(null);
          }}
        />
      )}
    </>
  );
}

export default memo(StaffDashboardSidebar);
const navbarWidth = 300;
const flexCenter = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};

function ItemButton({
  onClick,
  label,
  isParent = false,
  textAlign,
  fontSize,
  icon,
  fontColor,
  selected,
  fontWeight,
  sidebarOpened,
}) {
  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton selected={selected} onClick={onClick}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        {sidebarOpened !== false && (
          <ListItemText
            primary={
              isParent ? (
                label
              ) : (
                <Typography
                  color={fontColor}
                  pl={icon ? 1 : 0}
                  textAlign={textAlign ?? "left"}
                  fontSize={fontSize ?? 13}
                  fontWeight={fontWeight}
                >
                  {label}
                </Typography>
              )
            }
          />
        )}
      </ListItemButton>
    </ListItem>
  );
}

function generateDashboardLiks(e, history) {
  if (!e?.isChildren)
    return (
      <>
        {" "}
        <ItemButton
          fontWeight={400}
          fontSize={15}
          selected={window.location.pathname
            .replace("/en", "")
            .includes(e?.value)}
          onClick={() => {
            if (e.value && e.value.includes("newTab/")) {
              window.open(e.to, "_blank");
            } else {
              history.push(linkWithLanguage(e.to));
            }
          }}
          label={e.title}
          icon={e?.icon}
          fontColor={"white"}
        />
      </>
    );
  else {
    return <DashboardSubLinks history={history} e={e} />;
  }
}

function DashboardSubLinks({ history, e }) {
  const [listsOpen, setlistsOpen] = useState(false);
  return (
    <>
      <ListItemButton onClick={() => setlistsOpen(!listsOpen)}>
        <ListItemText
          primary={
            <Typography color={"white"} fontSize={13}>
              {e.title}
            </Typography>
          }
        />
        {listsOpen ? <ExpandLess sx={cWhite} /> : <ExpandMore sx={cWhite} />}
      </ListItemButton>
      <Collapse in={listsOpen}>
        {e.children.map((j, index) => {
          return <div key={index}>{generateDashboardLiks(j, history, 6)}</div>;
        })}
      </Collapse>
    </>
  );
}

function StaffProfile({ loyacStaff, history, sidebarOpened }) {
  return (
    <div>
      {sidebarOpened && (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Avatar
            alt={""}
            src={loyacStaff?.profile_image}
            sx={{
              width: 80,
              height: 80,
              marginY: "15px",
              display: "flex",
              border: "4px white solid",
              justifyContent: "center",
            }}
          />{" "}
        </Box>
      )}
      <Divider color="white" />
      <ItemButton
        onClick={() =>
          history.push(
            linkWithLanguage(`staff/${loyacStaff?.user.id}/profile/`)
          )
        }
        icon={<Person sx={cWhite} />}
        label={"Profile"}
        fontSize={18}
        fontWeight={600}
        fontColor={"white"}
        sidebarOpened={sidebarOpened}
      />{" "}
      <Divider color="white" />
      <Link to={linkWithLanguage("/account/settings")}>
        <ItemButton
          icon={<Settings sx={cWhite} />}
          label={"Settings"}
          fontSize={18}
          fontWeight={600}
          fontColor={"white"}
          sidebarOpened={sidebarOpened}
        />
      </Link>
      <Divider color="white" />
      <ItemButton
        onClick={() => logoutButton()}
        fontColor="white"
        icon={<LockReset sx={cWhite} />}
        label={"Logout"}
        fontSize={18}
        fontWeight={600}
        sidebarOpened={sidebarOpened}
      />
    </div>
  );
}
const openedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: navbarWidth,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 60,
});

const MuiDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const cWhite = { color: "white" };

function ToggleIcon({ bool }) {
  return bool ? <ExpandLess sx={cWhite} /> : <ExpandMore sx={cWhite} />;
}
